import axios from "@/libs/axios";
import { ProcDetailTypes, ProcSettingsTypes, ProcCodesTypes, ProcedureTypesTypes } from "@/types/procedureTypes";
import { ProcedureCodesDto } from '@/types/dtos/procedureCodesDto';
import {ProcedureSettingsDiagnosisDto} from '@/types/dtos/procedureSettingsDiagnosisDto';
import {ProcedureSettingsModifierDto} from '@/types/dtos/procedureSettingsModifierDto';
import {ProcedureSettingsPlaceOfServiceDto} from '@/types/dtos/procedureSettingsPlaceOfServiceDto';

export class HttpRepoProcedures {

	static ProcedureTypes = {
		getProcedureTypes(BaseFilters) {
			return axios.get<ListResponseModel<ProcedureTypesTypes>>('/ProcedureTypes/GetList', { params: BaseFilters })
			
		},
		// getProcedureTypes() {
		// 	return axios.get('/Home')
		// },
		addProcedureTypes(formData: ProcDetailTypes) {
			return axios.post<ModelResponse<ProcDetailTypes>>('/ProcedureTypes/AddType', formData)
		},
		updateProcedureTypes(formData: ProcDetailTypes) {
			return axios.post<ModelResponse<ProcDetailTypes>>('/ProcedureTypes/UpdateType', formData)
		},
		deleteProcedureTypes(Id: string) {
			return axios.delete<ResponseModel>('/ProcedureTypes/DeleteType', { params: { id: Id } })
		},
		addProcedureTypesSettings(formData: ProcSettingsTypes) {
			return axios.post<ModelResponse<ProcSettingsTypes>>('/ProcedureTypes/AddSetting', formData)
		}
	}
	static ProcedureCodes = {
		getData(BaseFilters) {
			return axios.get<ListResponseModel<ProcCodesTypes>>('/ProcedureCodes/Load', { params: BaseFilters })
		},
		getById(id: number) {
			//will include all nested values
			return axios.get<ModelResponse<ProcedureCodesDto>>('/ProcedureCodes/GetById', { params: { id: id } })
		}
		,
		add(dto: ProcCodesTypes) {
			return axios.post<ModelResponse<ProcCodesTypes>>('/ProcedureCodes/Add', dto)
		},
		update(dto: ProcedureCodesDto) {

			// fields left null will be set as null in database
			// nested entitys will not be updated from hare
			return axios.post<ModelResponse<ProcedureCodesDto>>('/ProcedureCodes/Update', dto)
		},
		delete(id: number) {
			// will set status to deleted
			return axios.delete<ModelResponse<ProcedureCodesDto>>('/ProcedureCodes/Delete', { params: { id: id } })
		},
		insertOrUpdateDiagnosis(procSettingsDiags:ProcedureSettingsDiagnosisDto[]){
			// to remove diagnosis just set status to deleted
			return axios.post<ModelResponse<ProcedureSettingsDiagnosisDto[]>>('/ProcedureCodes/InsertOrUpdateDiagnosis',procSettingsDiags)
		},
		insertOrUpdateModifiers(procSettingsMods:ProcedureSettingsModifierDto[]){
			return axios.post<ModelResponse<ProcedureSettingsModifierDto[]>>('/ProcedureCodes/InsertOrUpdateModifiers',procSettingsMods)
		},
		insertOrUpdatePos(procSettingsPos:ProcedureSettingsPlaceOfServiceDto[]){
			return axios.post<ModelResponse<ProcedureSettingsPlaceOfServiceDto[]>>('/ProcedureCodes/InsertOrUpdatePos',procSettingsPos)
		}

	}
}