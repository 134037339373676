/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  ProcedureBundlesDto,
  ProcedureBundlesDtoListResponseModel,
  ProcedureBundlesDtoModelResponse,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProcedureBundles extends HttpClient {
    /**
     * No description
     *
     * @tags ProcedureBundles
     * @name UpdateBundleUpdate
     * @request PUT:/ProcedureBundles/UpdateBundle
     */
    postPost = (data: ProcedureBundlesDto, params: RequestParams = {}) =>
        this.request<ProcedureBundlesDtoModelResponse, any>({
            path: `/ProcedureBundles/UpdateBundle`,
            method: "PUT",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name GetBundlesList
   * @request GET:/ProcedureBundles/GetBundles
   */
  getBundlesList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcedureBundlesDtoListResponseModel, any>({
      path: `/ProcedureBundles/GetBundles`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name GetGroupedBundlesList
   * @request GET:/ProcedureBundles/GetGroupedBundles
   */
  getGroupedBundlesList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcedureBundlesDtoListResponseModel, any>({
      path: `/ProcedureBundles/GetGroupedBundles`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name AddBundleCreate
   * @request POST:/ProcedureBundles/AddBundle
   */
  addBundleCreate = (data: ProcedureBundlesDto, params: RequestParams = {}) =>
    this.request<ProcedureBundlesDtoModelResponse, any>({
      path: `/ProcedureBundles/AddBundle`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name PostProcedureBundles
   * @request POST:/ProcedureBundles/Add
   */
  postProcedureBundles = (data: ProcedureBundlesDto, params: RequestParams = {}) =>
    this.request<ProcedureBundlesDtoModelResponse, any>({
      path: `/ProcedureBundles/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name LoadList
   * @request GET:/ProcedureBundles/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcedureBundlesDtoListResponseModel, any>({
      path: `/ProcedureBundles/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name GetByIdList
   * @request GET:/ProcedureBundles/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProcedureBundlesDtoListResponseModel, any>({
      path: `/ProcedureBundles/GetBundle`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name DeleteDelete
   * @request DELETE:/ProcedureBundles/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProcedureBundlesDtoModelResponse, any>({
      path: `/ProcedureBundles/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureBundles
   * @name UpdateCreate
   * @request POST:/ProcedureBundles/Update
   */
  updateCreate = (data: ProcedureBundlesDto, params: RequestParams = {}) =>
    this.request<ProcedureBundlesDtoModelResponse, any>({
      path: `/ProcedureBundles/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
