export default class ProceduresDataService {
  getProcedureTypes(id) {
    // return $http.get(`/demoget/edit/${id}`);
    return [
      {
        id: 6157,
        procType: 'Medical Services and Procedures',
        description: 'Consultation on X-ray examination made',
      },
      {
        id: 4987,
        procType: 'Pathology and Laboratory Services',
        description: 'Trnslum dil eye canal w/stnt',
      },
      {
        id: 3678,
        procType: 'Evaluation & Management Services',
        description: 'Dermatology Patch',
      },
      {
        id: 2015,
        procType: 'Radiology Services',
        description: 'Translum dil eye canal',
      },
      {
        id: 9600,
        procType: 'Surgery',
        description: 'IONM remote/>l pt or per hr',
      },
    ];
  }
  getProcedureCodes(id) {
    return [
      {
        id: 1,
        procCode: '87999',
        procType: 'Surgery',
        description: 'Consultation on X-ray examination made',
        rvuCode: '66175',
      },
      {
        id: 2,
        procCode: '1527B',
        procType: 'Pathology and Laboratory Services',
        description: 'Trnslum dil eye canal w/stnt',
        rvuCode: '1527B',
      },
      {
        id: 3,
        procCode: '15777',
        procType: 'Radiology Services',
        description: 'Dermatology Patch',
        rvuCode: '87999',
      },
      {
        id: 4,
        procCode: '81479',
        procType: 'Medical Services and Procedures',
        description: 'Translum dil eye canal',
        rvuCode: '95941',
      },
    ];
  }
  getActivityNotes(id) {
    return [];
  }
  getProcedureInfo(id) {
    return [
      {
        id: 1,
        feeName: 'Total Network Development',
        feeType: 'Type XYZ',
        feeAmount: 482,
        type: 'Fee schedule',
        overwritten: 'Corporation',
      },
      {
        id: 2,
        feeName: 'Rhodes Furniture',
        feeType: 'Type XYZ',
        feeAmount: 307,
        type: 'Fee schedule',
        overwritten: null,
      },
      {
        id: 3,
        feeName: 'Magna Architectural Design',
        feeType: 'Type XYZ',
        feeAmount: 275,
        type: 'Overwritten',
        overwritten: null,
      },
      {
        id: 4,
        feeName: 'Western Auto',
        feeType: 'Type XYZ',
        feeAmount: 482,
        type: 'Overwritten',
        overwritten: 'Profile',
      },
    ]
  }
  getProcedureBundles(id) {
    return [
      {
        id: 1,
        procCode: ['87999', '74115', '34417', '32177'],
        bundledProcCode: '15277',
        type: 'Allowed',
        overwrittenType: 'Not Applicable',
      },
      {
        id: 2,
        procCode: ['1527B'],
        bundledProcCode: '15276',
        type: 'Not Allowed',
        overwrittenType: 'Not Allowed',
      },
      {
        id: 3,
        procCode: ['15777', '98741', '12257'],
        bundledProcCode: '90937',
        type: 'Not Applicable',
        overwrittenType: 'Allowed',
      },
      {
        id: 4,
        procCode: ['88356', '23317'],
        bundledProcCode: '87999',
        type: 'Not Allowed',
        overwrittenType: 'Not Applicable',
      },
    ]
  }
  demoPost(data) {
    return $http.post(`/demopost`, data);
  }
}
